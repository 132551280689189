import Image from 'next/image';
import React from 'react';

import { cx } from '@emotion/css';

interface CardProps {
  styles: any;
  imageSrc: string;
  title: string;
  details: string;
}
const Card = (props: CardProps) => {
  const { styles, imageSrc, title, details } = props;
  return (
    <div className={styles.wcu_point}>
      <div className={styles.wcu_p_img}>
        <Image
          src={imageSrc}
          alt=""
          width={35}
          height={35}
          layout="intrinsic"
        />
      </div>
      <div className={styles.wcu_p_content}>
        <h4 className={cx(styles.h4, styles.point_title, styles.text_accentl)}>
          {title}
        </h4>
        <p className={cx(styles.p, styles.point_desc, styles.text_accentl)}>
          {details}
        </p>
      </div>
    </div>
  );
};
const WhyChooseUs = ({ styles }: any) => {
  return (
    <section className={cx(styles.sec, styles.wcu_sec)}>
      <div className={cx(styles.container, styles.inner)}>
        <div className={styles.row}>
          <div
            className={cx(styles.heading_blk, styles.text_center, styles.w100)}>
            <h2 className={cx(styles.h2, styles.title, styles.text_accentl)}>
              Why choose us?
            </h2>
          </div>
        </div>
        <div className={cx(styles.row, styles.spaceb, styles.wcu_point_row)}>
          <Card
            styles={styles}
            imageSrc="/images/icons/home.svg"
            title="3,000+"
            details="Completed jobs"
          />
          <Card
            styles={styles}
            imageSrc="/images/icons/users.svg"
            title="7"
            details="Multiple service lines"
          />
          <Card
            styles={styles}
            imageSrc="/images/icons/shield-plus.svg"
            title="Verified"
            details="Tradespeople you can trust"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
